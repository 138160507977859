<template>
  <el-affix :offset="10" position="top">
    <top-nav :logo="logoUrl" :title="titleUrl" />
  </el-affix>
  <router-view />
  <bottom-claim :logo="logoUrl" />
</template>


<script>
import TopNav from '@/components/TopNav'
import BottomClaim from '@/components/BottomClaim'
export default {
  components: { TopNav, BottomClaim },
  data() {
    return {
      logoUrl: require('@/assets/logo.png'),
      titleUrl: require('@/assets/brand.png'),
    }
  },
  created() {
    // 设置CSS变量的值
    document.documentElement.style.setProperty('--width', window.innerWidth + 'px');
    document.documentElement.style.setProperty('--height', window.innerHeight + 'px');
    // getComputedStyle(document.documentElement).getPropertyValue('--themeColor');
    // getComputedStyle(document.documentElement).getPropertyValue('--themeColor2');
  },
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Poppins;
  font-weight: 400;
  font-size: 1em;
  color: $theme-font-color;
  overflow-x: hidden;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // text-align: center;
  // color: $theme-color;
}
:root { // 全局变量
  --width: 0;
  --height: 0;
  --themeColor: $theme-color;
  --themeColor2: $theme-color2;
  --themeFontColor2: $theme-font-color;
}
</style>
