<template>
  <div class="home">
    <div class="home-banner">
      <el-button round class="home-banner-button" @click="handleShoping">&nbsp;&nbsp;&nbsp;{{title}}&nbsp;&nbsp;&nbsp;</el-button>
    </div>
    <div class="home-story">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
          <div class="home-story-col1">
            <el-image class="home-story-image1" fit="contain" :src="storyUrl1" alt="story" />
            <el-image class="home-story-image2" fit="contain" :src="storyUrl2" alt="story" />
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
          <div class="home-story-col2">
            <h3>{{title1[0]}}</h3>
            <p>{{title1[1]}}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="home-service">
      <el-image fit="fill" :src="serviceUrl" alt="service" />
    </div>
    <div class="home-vehicle">
      <h2>{{ title3[0] }}</h2>
      <div class="home-vehicle-image">
        <el-row :gutter="15">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-image fit="contain" :src="vehicleUrl1" alt="vechicle" @click="handleVehicle('Benz')" />
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-image fit="contain" :src="vehicleUrl2" alt="vechicle" @click="handleVehicle('Ford')" />
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <el-image fit="contain" :src="vehicleUrl3" alt="vechicle" @click="handleVehicle('Ram')" />
          </el-col>
        </el-row>
      </div>
      <div class="home-vehicle-strip">{{title3[1]}}</div>
    </div>
    <div class="home-product">
      <h2>{{title4}}</h2>
      <div>
        <span v-for="(item,idx) in productList" :key=item>
          <grid-item :gridIdx="idx" :gridIdy="idx" :itemData="item" @on-click="handleProductDetail" />
          <!-- <grid-item :itemData="item" @on-click="handleClick" /> -->
        </span>
      </div>
    </div>
</div>
</template>


<script>
import { Store } from '@/utils/store'
import { Lang } from '@/utils/language'
import { Consts } from '@/utils/consts'
import GridItem from '@/components/GridItem.vue'
export default {
  name: 'HomeView',
  components: { GridItem },
  data() {
    return {
      aHeight: window.screen.availHeight,
      storyUrl1: require('@/assets/home/story-1.png'),
      storyUrl2: require('@/assets/home/story-2.png'),
      serviceUrl: require('@/assets/home/service.png'),
      vehicleUrl1: require('@/assets/home/vehicle-1.png'),
      vehicleUrl2: require('@/assets/home/vehicle-2.png'),
      vehicleUrl3: require('@/assets/home/vehicle-3.png'),
      // production: require('@/assets/product/production.json'),
      production: [],
      productList: [],
    }
  },
  created() {
    const url = Consts.ProductUrl + 'production.json';
    fetch(url)
      .then(res => res.json())
      .then(data => { 
        this.productList = data.ProductGrid;
        for (const item of this.productList) {
          item.pics = Consts.ProductUrl + item.pics;
        }
      })
      .catch(err => { console.error('Download JSON file failed!', err); })
  },
  mounted() {
    // this.productList = this.production.ProductGrid;
  },  
  computed: {
    title() {
      if (Store.lang == 'en') {
        return Lang.Home.en[0];
      } else {
        return Lang.Home.cn[0];
      }
    },
    title1() {
      if (Store.lang == 'en') {
        return Lang.Home.en[1];
      } else {
        return Lang.Home.cn[1];
      }
    },
    title2() {
      if (Store.lang == 'en') {
        return Lang.Home.en[2];
      } else {
        return Lang.Home.cn[2];
      }
    },
    title3() {
      if (Store.lang == 'en') {
        return Lang.Home.en[3];
      } else {
        return Lang.Home.cn[3];
      }
    },    
    title4() {
      if (Store.lang == 'en') {
        return Lang.Home.en[4];
      } else {
        return Lang.Home.cn[4];
      }
    },    
    aboutText() {
      if (Store.lang == 'en') {
        return Lang.Home.en;
      } else {
        return Lang.Home.cn;
      }
    },
    modelText() {
      if (Store.lang == 'en') {
        return Lang.Model.en;
      } else {
        return Lang.Model.cn;
      }
    },
  },
  methods: {
    handleShoping() {
      this.$router.push({
        name: 'shop',
        params: {
          category: 'general'
        }
      })
    },
    handleVehicle(str) {
      this.$router.push({
        name: 'shop',
        params: {
          category: 'vehicle',
          brand: str,
        }
      })
    },
    handleProductDetail(idx) {
      this.$router.push({
        name: 'product',
        params: {
          data: JSON.stringify(this.productList[idx])
        }
      })
    },    
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';
.home {
  width: 100%;
}
.home-banner {
  width: auto;
  height: calc(var(--width) * 0.40);
  // height: 640px;
  background-image: url('../assets/home/banner.png');
  background-size: cover;
}
.home-banner-button {
  margin-top: 25%;
  margin-left: 25%;
}
.home-story {
  width: auto;
  // height: 760px;
}
.home-story-col1 {
  display: flex;            /* 使用Flexbox布局 */
  flex-direction: row;      /* 设置主轴方向为水平 */
  justify-content: right;   /* 主轴上的对齐方式，即水平靠右对齐 */
  align-items: center;      /* 交叉轴上的对齐方式，即垂直居中对齐 */
  // padding: 20px 0 40px 10px;
}
.home-story-col2 {
  display: flex;            /* 使用Flexbox布局 */
  flex-direction: column;   /* 设置主轴方向为垂直 */
  justify-content: center;  /* 主轴上的对齐方式，即垂直居中对齐 */
  align-items: left;        /* 交叉轴上的对齐方式，即水平靠左对齐 */
  // padding: 30% 0;
}
.home-story-image1 {
  // margin-top: 20px;
  width: 340px;
  // height: 560px;
}
.home-story-image2 {
  // margin-top: 140px;
  width: 340px;
  // height: 560px;
}
.home-service {
  width: auto;
  height: calc(var(--width) * 0.35);  
  // height: 585px;
  // background-image: url('../assets/home/service.png');
  // background-size: cover;
}
.home-vehicle {
  text-align: center;
  padding: 80px 10% 0 10%;
  width: auto;
  // height: 560px;
}
.home-vehicle-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.home-vehicle-image .el-image {
  cursor: pointer;
}
.home-vehicle-strip {
  display: flex;            /* 使用Flexbox布局 */
  flex-wrap: wrap;          /* 允许Flex项目换行到新的行或列 */
  flex-direction: row;      /* 设置主轴方向为水平 */
  justify-content: center;  /* 主轴上的对齐方式，即水平居中对齐 */
  align-items: center;      /* 交叉轴上的对齐方式，即垂直居中对齐 */
  padding-left: 100px;
  padding-right: 100px;
  width: auto;
  height: 120px;
  background-image: url('../assets/home/stripe.png');
  background-size: contain;
  font-size: 0.8em;
  color: #FFFFFFB2;
}
.home-product {
  padding: 40px 10% 0 10%;
  width: auto;
  height: 640px;
  text-align: center;
}
.home-product div {
  // display: inline-flex;     /* 在内联级，使用Flexbox布局 */
  display: flex;            /* 在块级，使用Flexbox布局 */
  flex-direction: row;      /* 设置主轴方向为水平 */
  overflow-x: auto;
}


/* 设置了浏览器宽度不小于1201px时 list显示的宽度 */
@media screen and (min-width: 1201px) {
// .home-banner {
//   height: calc(var(--width) * 0.41);
// }
.home-story {
  padding: 40px 10% 80px 10%;
}
.home-story-col2 {
  padding: 30% 0;
}
.home-story-image2 {
  margin-top: 140px;
}
// .home-service {
//   height: calc(var(--width) * 0.355);
// }
// .home-vehicle-stripe {
//   padding-top: 30%;
//   padding-left: 100px;
//   text-align: left;
// }
}
@media screen and (max-width: 1200px) {
//  .home-banner {
//   height: calc(var(--width) * 0.40);
// }
.home-story {
  padding: 0 10%;
}
.home-story-col2 {
  padding: 10px 0;
}
.home-story-image2 {
  margin-top: 80px;
}
// .home-service {
//   height: calc(var(--width) * 0.355);
// }
// .home-vehicle-stripe {
//   text-align: center;
// }
}
</style>
