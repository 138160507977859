<template>
  <div class="bottom">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
        <div>
          <el-image class="bottom-logo" :fit="'fill'" :src="logoUrl" alt="logo" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <h3>Contact Us</h3>
        <p class="bottom-contact">
          +1 (604) 354-3868<br>
          info@hypertreck.ca<br>
          Shop Loaction:&nbsp;&nbsp;&nbsp;&nbsp; 23886 106 Ave, Maple Ridge, BC V2W 1E4<br>
          Mail Address:&nbsp;&nbsp;&nbsp;&nbsp; 23886 106 Ave, Maple Ridge, BC V2W 1E4
        </p>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
        <h3>Payment by</h3>
        <div>
          <el-image class="bottom-payment-logo" :src="urlExpress" fit="fill" /> 
          <el-image class="bottom-payment-logo" :src="urlMaster" fit="fill" /> 
          <el-image class="bottom-payment-logo" :src="urlVisa" fit="fill" /> 
        </div>
      </el-col>
    </el-row>
    <el-divider />
    <div class="bottom-claim">
      <p>
        <span>Copyright©2023-{{thisYear}} HyperTrek.</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="/policy/terms-of-service.html">Terms of Service</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="/policy/privacy-policy.html">Privacy Policy</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="/policy/shop-policy.html">Shop Policy</a>
      </p>
      <p>
        <a href="https://fairycow.ca" alt="Developed by FairyCOW" target="_blank">Developed by FairyCOW</a>
      </p>
    </div>    
  </div>
</template>


<script>
export default {
  name: 'BottomClaim',
  props: {
    logo: {
      reuired: true,
      type: String
    }
  },
  data() {
    return {
      urlExpress: require('@/assets/payment/express.png'),
      urlMaster: require('@/assets/payment/master.png'),
      urlVisa: require('@/assets/payment/visa.png'),
      urlInterac: require('@/assets/payment/interac.png'),
      urlPayPal: require('@/assets/payment/paypal.png'),
      urlWechatPay: require('@/assets/payment/wechatpay.png'),
      urlAlipay: require('@/assets/payment/alipay.png'),
      logoUrl: this.logo,
      thisYear: new Date().getFullYear(),
    }
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';
h3 {
  color: white;
}
a {  
  text-decoration: none;
  color: #FFFFFFB2;
}
.bottom {
  z-index: 1;
  width: auto;
  min-height: $bottom-height;
  background-color: $theme-color2;
  color: #FFFFFFB2;
  font-family: Poppins;
  font-size: 1em;
  text-align: left;
}
.bottom-logo {
  margin-top: 20px;
  width:156px; 
  height:131px;
}
.bottom-contact {

}
.bottom-payment-logo {
  padding: 0 3px;
  width:70px; 
}
.bottom-claim {
  font-size: 0.8em;
  text-align: center;
}
/* 设置了浏览器宽度不小于1201px时 list显示的宽度 */
@media screen and (min-width: 1201px) {
.bottom {
  padding: 20px 80px;
}
}
@media screen and (max-width: 1200px) {
.bottom {
  padding: 1px 10px;
}
}
</style>