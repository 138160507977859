const PageSize = 20;
const CompanyName = 'HyperTrek';
const StorageUrl = 'https://storage.googleapis.com/hypertrek/';
const ProductUrl = StorageUrl + 'product/';
const InstallUrl = StorageUrl + 'install/';
const LifeshowUrl = StorageUrl + 'lifeshow/';

export const Consts = {
  PageSize,
  CompanyName,
  StorageUrl,
  ProductUrl,
  InstallUrl,
  LifeshowUrl,
} 
