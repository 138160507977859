<template>
  <div class="grid-item" @click="handleClick">
    <el-card>
      <div class="grid-item-image">
        <el-image fit="fill" :src="gridPic" />
      </div>
      <div class="grid-item-desc">
        <h3>{{ gridName }}</h3>
        <p>{{ gridDesc }}</p>
        <h3>{{ gridPrice }}</h3>
      </div>
    </el-card>
  </div>
</template>


<script>
export default {
  name: 'GridItem',
  props: {
    gridIdx: {
      required: true,
      type: Number
    },
    gridIdy: {
      required: true,
      type: Number
    },
    itemData: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      idx: this.gridIdx,
      idy: this.gridIdy,
      gridName: this.itemData.name,
      gridDesc: this.itemData.desc,
      gridPrice: this.itemData.price,
      gridPic: this.itemData.pics,
      // gridPic: require('@/assets/product/product-4.png'),
    }
  },
  created() {
    // console.log(this.gridPic);
    // this.gridPic = require(this.itemData.pics);
  },  
  methods: {
    handleClick() {
      this.$emit('on-click', this.idx, this.idy);
    }
  }
}
</script>


<style lang="scss" scoped>
.grid-item {
  padding: 0 5px 5px 5px;
  width: 340px;
  height: 480px;
  cursor: pointer;
}
.grid-item-image {
  text-align: center;
  width: 320px;
  height: 320px;  
}
.grid-item-desc {
  text-align: left;
  font-size: 0.7em;
  word-break: break-all;
}

/* 设置了浏览器宽度不小于1201px时 */
// @media screen and (min-width: 1201px) {
// .grid-item {
//   width: calc(var(--width) * 0.25);
//   height: calc(var(--width) * 0.25 * 1.33);
// }
// .grid-item-image {
//   .el-image {
//     width: calc(var(--width) * 0.18);
//     height: calc(var(--width) * 0.18);
//   }
// }
// }
// @media screen and (max-width: 1200px) {
// .grid-item {
//   width: calc(var(--width) * 0.8);
//   height: calc(var(--width) * 0.8 * 1.33);
// }
// .grid-item-image {
//   .el-image {
//     width: calc(var(--width) * 0.5);
//     height: calc(var(--width) * 0.5);
//   }
// }
// }
</style>