import { reactive } from 'vue'

function getOrderQuantity() {
  const storage = localStorage.getItem('hypertrek');
  if (storage !== null) {
    let sum = 0;
    const orders = JSON.parse(storage)
    for (let item of orders) {
      sum += item.quantity;
    }
    return sum;
} else {
    return 0;
  }
}

export const Store = reactive({
  lang: 'en',
  changeLang(val) { 
    this.lang = val;
  },

  preIndex: '1',
  changePreIndex(val) {
    this.preIndex = val;
  },

  largeScreen: window.innerWidth >= 1200 ? true : false,
  changeScreen() {
    if (window.innerWidth < 1200) {
      this.largeScreen = false;
    } else {
      this.largeScreen = true;
    }
  },

  // order: 0,
  order: getOrderQuantity(),
  changeOrder(val) {
    this.order = val;
  }
})