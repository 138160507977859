<template>
  <div class="top-nav">
    <div v-if="isLargeScreen">
      <el-row :gutter="10">
        <!-- <el-col :xs="{span:20, push:1}" :sm="{span:20, push:1}" :md="{span:20, push:1}" :lg="12" :xl="12"> -->
        <el-col :span="6">
          <div class="top-logo" @click="handleLogo">
            <el-image class="top-logo-image" :fit="'fill'" :src="logoUrl" alt="logo" />
            <el-image class="top-logo-title" :fit="'fill'" :src="titleUrl" alt="title" />
            <!-- <span class="top-logo-title">&nbsp;{{titleName}}</span> -->
          </div>
        </el-col>
        <!-- <el-col :xs="{span:4, pull:2}" :sm="{span:4, pull:2}" :md="{span:4, pull:2}" :lg="12" :xl="12"> -->
        <el-col :span="12">
          <div class="top-menu">
            <el-menu mode="horizontal" :default-active="activeIndex" :active-text-color="activeTextColor" :text-color="textColor" :background-color="backgroundColor" @select="handleSelect">
              <!-- <el-menu-item index="1"><span class="top-menu-item">{{menu[0]}}</span></el-menu-item> -->
              <!-- <el-sub-menu index="2">
                <template #title><span class="top-menu-item">{{menu[1].name}}</span></template>
                <el-sub-menu index="2-1">
                  <template #title>{{menu[1].submenu[0].name}}</template>
                  <el-menu-item index="2-1-1">{{menu[1].submenu[0].submenu[0]}}</el-menu-item>
                  <el-menu-item index="2-1-2">{{menu[1].submenu[0].submenu[1]}}</el-menu-item>
                  <el-menu-item index="2-1-3">{{menu[1].submenu[0].submenu[2]}}</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="2-2">
                  <template #title>{{menu[1].submenu[1].name}}</template>
                  <el-menu-item index="2-2-1">{{menu[1].submenu[1].submenu[0]}}</el-menu-item>
                  <el-menu-item index="2-2-2">{{menu[1].submenu[1].submenu[1]}}</el-menu-item>
                  <el-menu-item index="2-2-3">{{menu[1].submenu[1].submenu[2]}}</el-menu-item>
                  <el-menu-item index="2-2-4">{{menu[1].submenu[1].submenu[3]}}</el-menu-item>
                  <el-menu-item index="2-2-5">{{menu[1].submenu[1].submenu[4]}}</el-menu-item>
                  <el-menu-item index="2-2-6">{{menu[1].submenu[1].submenu[5]}}</el-menu-item>
                </el-sub-menu>                
              </el-sub-menu> -->
              <el-sub-menu index="2">
                <template #title><span class="top-menu-item">{{menu[1].name}}</span></template>
                <el-menu-item index="2-0">{{menu[1].submenu[0]}}</el-menu-item>
                <el-menu-item index="2-1">{{menu[1].submenu[1]}}</el-menu-item>
                <el-menu-item index="2-2">{{menu[1].submenu[2]}}</el-menu-item>
              </el-sub-menu>              
              <el-sub-menu index="3">
                <template #title><span class="top-menu-item">{{menu[2].name}}</span></template>
                <el-menu-item index="3-0">{{menu[2].submenu[0]}}</el-menu-item>
                <el-menu-item index="3-1">{{menu[2].submenu[1]}}</el-menu-item>
                <el-menu-item index="3-2">{{menu[2].submenu[2]}}</el-menu-item>
                <el-menu-item index="3-3">{{menu[2].submenu[3]}}</el-menu-item>
                <el-menu-item index="3-4">{{menu[2].submenu[4]}}</el-menu-item>
                <el-menu-item index="3-5">{{menu[2].submenu[5]}}</el-menu-item>
                <el-menu-item index="3-6">{{menu[2].submenu[6]}}</el-menu-item>
              </el-sub-menu>
              <el-menu-item index="4"><span class="top-menu-item">{{menu[3]}}</span></el-menu-item>
              <el-menu-item index="5"><span class="top-menu-item">{{menu[4]}}</span></el-menu-item>
              <el-menu-item index="6">
                <el-switch 
                  v-model="switchValue" 
                  inline-prompt
                  active-text="EN"
                  inactive-text="中" 
                  size="large" 
                  style="--el-switch-on-color: #6c8c84; --el-switch-off-color: #ff4949; padding-top: 8px;"
                />
              </el-menu-item>
            </el-menu>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="top-info">
            <el-badge :value="orderQuantity" :hidden="hiddenBadge">
              <el-icon size="30" @click="handleCart"><ShoppingCart /></el-icon>
            </el-badge>
            &nbsp;
            <!-- <span class="top-info-user"> -->
            <el-badge>
              <el-icon size="30" @click="handleUser"><User /></el-icon>          
            </el-badge>
            <!-- </span> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <el-row :gutter="10">
        <el-col :span="4">
          <div class="top-menu">
            <el-button @click="handleOpen"><el-icon><Fold /></el-icon></el-button>
            <el-drawer v-model="isOpenDrawer" :show-close="false" :append-to-body="true" size="60%" direction="ltr" :before-close="handleClose">
              <el-menu mode="vertical" :default-active="activeIndex" :active-text-color="activeTextColor" :text-color="textColor" :background-color="backgroundColor" @select="handleSelect">
                <!-- <el-menu-item index="1"><span class="top-menu-item">{{menu[0]}}</span></el-menu-item> -->
                <!-- <el-sub-menu index="2">
                  <template #title><span class="top-menu-item">{{menu[1].name}}</span></template>
                  <el-sub-menu index="2-1">
                    <template #title>{{menu[1].submenu[0].name}}</template>
                    <el-menu-item index="2-1-1">{{menu[1].submenu[0].submenu[0]}}</el-menu-item>
                    <el-menu-item index="2-1-2">{{menu[1].submenu[0].submenu[1]}}</el-menu-item>
                    <el-menu-item index="2-1-3">{{menu[1].submenu[0].submenu[2]}}</el-menu-item>
                  </el-sub-menu>
                  <el-sub-menu index="2-2">
                    <template #title>{{menu[1].submenu[1].name}}</template>
                    <el-menu-item index="2-2-1">{{menu[1].submenu[1].submenu[0]}}</el-menu-item>
                    <el-menu-item index="2-2-2">{{menu[1].submenu[1].submenu[1]}}</el-menu-item>
                    <el-menu-item index="2-2-3">{{menu[1].submenu[1].submenu[2]}}</el-menu-item>
                    <el-menu-item index="2-2-4">{{menu[1].submenu[1].submenu[3]}}</el-menu-item>
                    <el-menu-item index="2-2-5">{{menu[1].submenu[1].submenu[4]}}</el-menu-item>
                    <el-menu-item index="2-2-6">{{menu[1].submenu[1].submenu[5]}}</el-menu-item>
                  </el-sub-menu>                
                </el-sub-menu> -->
                <el-sub-menu index="2">
                  <template #title><span class="top-menu-item">{{menu[1].name}}</span></template>
                  <el-menu-item index="2-0">{{menu[1].submenu[0]}}</el-menu-item>
                  <el-menu-item index="2-1">{{menu[1].submenu[1]}}</el-menu-item>
                  <el-menu-item index="2-2">{{menu[1].submenu[2]}}</el-menu-item>
                </el-sub-menu>                
                <el-sub-menu index="3">
                  <template #title><span class="top-menu-item">{{menu[2].name}}</span></template>
                  <el-menu-item index="3-0">{{menu[2].submenu[0]}}</el-menu-item>
                  <el-menu-item index="3-1">{{menu[2].submenu[1]}}</el-menu-item>
                  <el-menu-item index="3-2">{{menu[2].submenu[2]}}</el-menu-item>
                  <el-menu-item index="3-3">{{menu[2].submenu[3]}}</el-menu-item>
                  <el-menu-item index="3-4">{{menu[2].submenu[4]}}</el-menu-item>
                  <el-menu-item index="3-5">{{menu[2].submenu[5]}}</el-menu-item>
                  <el-menu-item index="3-6">{{menu[2].submenu[6]}}</el-menu-item>
                </el-sub-menu>
                <el-menu-item index="4"><span class="top-menu-item">{{menu[3]}}</span></el-menu-item>
                <el-menu-item index="5"><span class="top-menu-item">{{menu[4]}}</span></el-menu-item>
                <el-menu-item index="6">
                  <el-switch 
                    v-model="switchValue" 
                    inline-prompt
                    active-text="EN"
                    inactive-text="中" 
                    size="large" 
                    style="--el-switch-on-color: #6c8c84; --el-switch-off-color: #ff4949; padding-top: 8px;"
                  />
                </el-menu-item>
              </el-menu>
            </el-drawer>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="top-logo" @click="handleLogo">
            <el-image class="top-logo-image" :fit="'fill'" :src="logoUrl" alt="logo" />
            <el-image class="top-logo-title" :fit="'fill'" :src="titleUrl" alt="title" />
            <!-- <span class="top-logo-title">&nbsp;{{titleName}}</span> -->
          </div>
        </el-col>
        <el-col :span="8">
          <div class="top-info">
            <el-badge :value="orderQuantity" :hidden="hiddenBadge">
              <el-icon size="30" @click="handleCart"><ShoppingCart /></el-icon>
            </el-badge>
            &nbsp;
            <el-badge>
              <el-icon size="30" @click="handleUser"><User /></el-icon>          
            </el-badge>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>


<script>
import { Store } from '@/utils/store'
import { Lang } from '@/utils/language'
// import { Consts } from '@/utils/consts'
import { User, ShoppingCart, Fold } from '@element-plus/icons-vue'
export default {
  name: 'TopNav',
  components: { User, ShoppingCart, Fold },
  props: {
    logo: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      isLargeScreen: Store.largeScreen,
      isOpenDrawer: false, // 控制菜单项的折叠状态，默认为展开
      activeIndex: '1',
      activeTextColor: '#6c8c84',
      backgroundColor: '#161819',
      textColor: '#FFFFFF',
      switchValue: true,
      logoUrl: this.logo,
      titleUrl: this.title,
      // titleName: Consts.CompanyName,
    }
  },
  created() {
    // console.log(this.activeTextColor)
  },
  mounted() {
    // this.activeTextColor = getComputedStyle(document.documentElement).getPropertyValue('--themeColor');
    // this.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--themeColor2');
  },
  computed: {
    menu() {
      if (this.switchValue) {
        Store.changeLang('en');
        return Lang.Menu.en;
      } else {
        Store.changeLang('cn');
        return Lang.Menu.cn;
      }
    },
    hiddenBadge() {
      return this.orderQuantity == 0 ? true : false;
    },
    orderQuantity() {
      return Store.order;
    },
  },
  methods: {
    handleOpen(){
        this.isOpenDrawer = true;
    },
    handleClose(){
        this.isOpenDrawer = false;
    },    
    handleLogo(){
      this.$router.push({ name: 'home' });
    },
    handleSelect(idx) {
      switch(idx) {
        case '1':
          this.$router.push({ name: 'home' });
          break;
        // case '2-1-1':
        // case '2-1-2':
        // case '2-1-3':
        // case '2-2-1':
        // case '2-2-2':
        // case '2-2-3':
        // case '2-2-4':
        // case '2-2-5':
        // case '2-2-6':
        //   this.$router.push({ name: 'accessory' });
        //   break;
        case '2-0':
          this.$router.push({ name:'shop', params:{ category: 'general' } });
          break;
        case '2-1':
          this.$router.push({ name:'shop', params:{ category: 'vehicle' } });
          break;
        case '2-2':
          this.$router.push({ name:'shop', params:{ category: 'product'} });
          break;
        case '3-0':
        case '3-1':
        case '3-2':
        case '3-3':
        case '3-4':
        case '3-5':
        case '3-6':
          this.$router.push({ name: 'install' });
          const element = document.querySelector('#service-'+idx);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }          
          break;
        case '4':
          this.$router.push({ name: 'lifeshow' });
          break;
        case '5':
          this.$router.push({ name: 'about' });
          break;
        case '6':
        default:
          this.activeIndex = Store.preIndex;
      }
      this.handleClose();
    },
    handleCart() {
      this.$router.push({ name: 'cart' });
    },
    handleUser() {
      // this.$router.push({ name: 'user' });
    },
  }
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
.top-nav {
  z-index: 1;
  width: auto;
  height: $top-height;
  border-bottom: 1px solid rgba(144, 147, 153, 0.2);
  background-color: $theme-color2;
}
.top-logo {
  cursor: pointer;
}
.top-logo-image {
  width: 64px;
  height: 54px;
  vertical-align: middle;
}
.top-logo-title {
  margin-left: 10px;
  width: 85px;
  height: 24px;
  vertical-align: middle;
}
// .top-logo-title {
//   font-weight: 700;
//   color: $theme-font-color;
//   vertical-align: middle;
// }
.top-menu {
  padding-top: 10px;
}
.top-menu-item {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
  // line-height: 1.5em;
  text-align: center;
  vertical-align: middle;
}
.top-info {
  padding-top: 20px;
  color: $theme-color;
  text-align: right;
  cursor: pointer;
}

/* 设置了浏览器宽度不小于1201px时 list显示的宽度 */
@media screen and (min-width: 1201px) {
.top-menu {
  padding-top: 10px;
}  
.top-logo {
  padding-top: 10px;
  padding-left: 40px;
}
// .top-logo-title {
//   font-size: 2.5em;
// }
.top-info {
  padding-right: 50px;
}
}
@media screen and (max-width: 1200px) {
.top-menu {
  padding-top: 20px;
  padding-left: 10px;
  text-align: left;
}
.top-logo {
  padding-top: 10px;
  text-align: center;
}
// .top-logo-title {
//   font-size: 2.0em;
// }
.top-info {
  padding-right: 20px;
}
}
</style>
