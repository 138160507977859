const Menu = {
  en: [
    'Home', 
    {
      name:'Shop', 
      submenu:[
        'General',
        'By Vehicle',
        'By Product'
        // {name:'By Vehicle', submenu:['Benz Sprinter Van', 'Ford Transit Van', 'Ram ProMaster Van']},
        // {name:'By Product', submenu:['item one', 'item two', 'item three', 'item four', 'item five', 'item six']}
      ]
    }, 
    {
      name:'Installation', 
      submenu:[
        'All Services',
        'Indy Protective Coating',
        'PODS + Windows', 
        'Skid Plates + Catalytic Converter',
        'G.O.A.T (Bumpers) + APEX (Sliders) + IBEX (Ladders)',
        'Roof Rack + Roof Accessories + Lights',
        'Miscellaneous'
      ]
    }, 
    'Traveling', 
    'AboutUs'
  ],
  cn: [
    '主页', 
    {
      name:'购买', 
      submenu:[
        '浏览全部',
        '按车型',
        '按产品'
        // {name:'按车型', submenu:['斯宾特房车', '福特全顺房车', '普罗马斯特房车']},
        // {name:'按产品', submenu:['类型1', '类型2', '类型3', '类型4', '类型5', '类型6']}
      ]
    }, 
    {
      name:'改装', 
      submenu:[
        '全部服务',
        '印地防护涂层',
        '吊舱 + 窗户', 
        '防滑板 + 催化转化器',
        'G.O.A.T(保险杠)+ APEX(滑块)+ IBEX(梯子)',
        '车顶行李架 + 配件 + 灯',
        '杂项'
      ]
    }, 
    '旅行', 
    '关于'
  ],
};

const Home = {
  en: [
    'SHOP NOW',
    [
      'Some Stories About Us',
      'HyperTrek is a collective of skilled workers who live to play in the outdoors and share the same goals: to create innovative,durable and easy-to-use products to help campervan enthusiast carry the gear they need to their happy place in the outdoors.HyperTrek is a collective of skilled workers who live to play in the outdoors and share the same goals: to create innovative,durable and easy-to-use products to help campervan enthusiast carry the gear they need to their happy place in the outdoors.',
      'More About Us',
    ],
    'One-Stop Service',
    [
      'Choose Your Vehicle',
      'We use and test our own products and we understand the people that are trying to make the most of the Friday night to Sunday time window.Our products are crafted for real world use, where dirt and mud meet snow and ice.Designed for the unforgiving Canadian climate, they will exceed your expectations anywhere in the world.'
    ],
    'Featured Products'
  ],
  cn: [
    '现在购买',
    [
      '关于我们的故事',
      'HyperTrek 是一群技术熟练的工人，他们热爱户外生活，并且有着共同的目标：创造创新、耐用且易于使用的产品，帮助露营车爱好者携带他们需要的装备到户外的快乐之地。HyperTrek 是一群技术熟练的工人，他们热爱户外生活，并且有着共同的目标：创造创新、耐用且易于使用的产品，帮助露营车爱好者携带他们需要的装备到户外的快乐之地',
      '更多关于我们',
    ],
    [
      '选择您心仪的房车',
      '我们使用并测试我们自己的产品，我们了解人们试图充分利用周五晚上到周日的时间段。我们的产品专为现实世界使用而设计，在那里泥土和泥浆遇到雪和冰。专为严酷的加拿大气候而设计，它们将在世界任何地方超出您的期望。'
    ],
    '特色产品'
  ],
};
const Shop = {
  en: ['Filtered', 'Vehicle', 'Exterior', 'Interior', 'Price'],
  cn: ['已筛选', '车型', '外部', '内饰', '价格'],
};
const Install = {
  en: [
    'We serve the following install', 
    'Safeguard your vehicle with a durable bed liner that will protect your van and products for years to come.\nChoose from one of our packages or request a quote for something specific you have in mind'
  ],
  cn: [
    '我们提供如下改装服务', 
    '使用耐用的货箱衬垫保护您的车辆，这将在未来几年内保护您的货车和产品。\n从我们的套餐中选择一个，或针对您想到的特定套餐索取报价'
  ],
};
const Lifeshow = {
  en: [
    [
      'Chase the Aurora together',
      'Click to view aurora guide, Click to view aurora guide, Click to view aurora guide, Click to view aurora guide, Click to view aurora guide.',
      'READ MORE',
    ],
    'Our customers wonderful RV lifeshow',
    'Vidoes',
    [
      'Go modify your campervan',
      'Start your own camping trip',
      'SHOP NOW',
    ],
  ],
  cn: [
    [
      '一起追逐极光',
      '点击查看极光指南，点击查看极光指南，点击查看极光指南，点击查看极光指南，点击查看极光指南。',
      '了解更多',
    ],
    '我们客户的精彩房车生活',
    '视频',
    [
      '改装你的露营房车',
      '开始您的户外旅行',
      '现在购买',
    ],
  ],
};
const AboutUs = {
  en: [
    [
      'How did HyperTrek start?',
      'Here is the copy content here is the copy content here is the copy content,here is the copy content here is the,Here is the copy content here is the copy content here is the copy content,here is the copy content here is the,Here is the copy conten.',
    ],
    [
      "What does the name 'HyperTrek' actually mean?",
      'Here is the copy content here is the copy content here is the copy content,here is the copy content here is the,Here is the copy content here is the copy content here is the copy content,here is the copy content here is the,Here is the copy conten.',
    ],
    [
      'Meet our Management Team',
      {
        name:'Harry', 
        desc:[
          'Harry is a 20+ year seasoned traveler having and has seen much of North America via camper vans. He has a passion for designing quality camper van products that are worry-free and let him focus on what really matters: over landing with a purpose.',
          'When Simon isn’t in the shop working with wood, fibreglass, steel or aluminum he’s out hiking, Mountain Biking, Trail Building, Backcountry Skiing, and Paddle Boarding.',
          'Husband and father of two boys he understands the importance of having a well organized camper to make the most of every weekend with the family.'
        ]
      },
      {
        name:'William', 
        desc:[
          'William’s love of travelling began in 2001 on a two month journey across Canada in a camper van thus prompting her to feel that Van’s would always be a part of her family life.',
          'enjoys adventuring in the mountains with her family and their dog Watson and outdoor activities such as Mountain biking, paddle boarding, skiing and enjoying good food together.',
          'When she’s not in the office looking after Backlands valued clients, you can find her looking for remote camping spots to find peace and tranquillity on the weekends.'
        ]
      },
      {
        name:'Logan', 
        desc:[
          'Harry is a 20+ year seasoned traveler having and has seen much of North America via camper vans. He has a passion for designing quality camper van products that are worry-free and let him focus on what really matters: over landing with a purpose.',
          'When Simon isn’t in the shop working with wood, fibreglass, steel or aluminum he’s out hiking, Mountain Biking, Trail Building, Backcountry Skiing, and Paddle Boarding.',
          'Husband and father of two boys he understands the importance of having a well organized camper to make the most of every weekend with the family.'
        ]
      },
    ],
  ],
  cn: [
    [
      'HyperTrek 是如何开始的？', 
      '这里是复制的内容这里是复制的内容这里是复制的内容，这里是复制的内容这里是，这里是复制的内容这里是复制的内容这里是复制的内容，这里是复制的内容这里是，这里是复制的内容。', 
    ],
    [
      'HyperTrek这个名字究竟意味着什么？', 
      '这里是复制的内容这里是复制的内容这里是复制的内容，这里是复制的内容这里是，这里是复制的内容这里是复制的内容这里是复制的内容，这里是复制的内容这里是，这里是复制的内容。', 
    ],
    [
      '认识我们的管理团队', 
      {
        name:'韩宇',
        desc:[
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。',
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。',
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。'
        ]
      },
      {
        name:'威廉',
        desc:[
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。',
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。',
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。'
        ]
      },
      {
        name:'洛根',
        desc:[
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。',
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。',
          'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。'
        ]
      }
    ]
  ],
};
const Product = {
  en: ['Choose RV accessory you want to buy', 'Technical data', 'Quantity', 'Add to cart'],
  cn: ['选择您要购买的房车配件', '技术参数', '数量', '加入购物车'],
};
const Cart = {
  en: ['Your shopping cart', 'PRODUCT', 'QUANTIY', 'Subtotal', 'Checkout', 'Delivery', 'GST', 'PST', 'Total', 'Payment'],
  cn: ['您的购物车', '物品', '数量', '商品金额', '去结算', '运费', '联邦税', '省税', '总金额', '付款'],
};
const Payment = {
  en: ['You are paying', 'Payment method', 'Credit Card', 'Online Transfer', 'Confirm'],
  cn: ['您正在付款', '支付方式', '信用卡', '互联网支付', '确认付款'],
};
const Credit = {
  en: ['Card Number', 'Expiration Date', 'CVV', 'First Name', 'Last Name'],
  cn: ['信用卡号', '失效日期', 'CVV', '名', '姓'],
}


export const Lang = {
  Menu,
  Home,
  Shop,
  Install,
  Lifeshow,
  AboutUs,
  Product,
  Cart,
  Payment,
  Credit,
}